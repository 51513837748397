








































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import ModalPlans from './ModalPlans.vue'
import { Getter } from 'vuex-class'

@Component({
  components: {
    ModalPlans,
  },
})
export default class ModalVideoSettings extends Vue {
  @Prop() isRendering!: boolean

  @Getter networkType!: string

  showPlans = false
  resolution = 720
  resolutionList = [
    { title: '480p', value: 480, isPremium: false },
    { title: '720p', value: 720, isPremium: false },
    { title: '1080p', value: 1080, isPremium: true },
    // { title: '1440p', value: 1440, isPremium: true },
    // { title: '2160p', value: 2160, isPremium: true },
  ]

  orientation = 'LANDSCAPE'
  orientationList = [
    { title: 'Landscape', value: 'LANDSCAPE' },
    { title: 'Square', value: 'SQUARE' },
    { title: 'Portrait', value: 'PORTRAIT' },
  ]

  subtitle = true
  subtitleList = [
    { title: 'Burn subtile', value: true },
    { title: 'No subtitle', value: false },
  ]

  backgroundFit = 'containblur'
  backgroundFitList = [
    { title: 'Cover', value: 'cover' },
    { title: 'Blur', value: 'containblur' },
  ]

  transition = 'random'
  transitionList = [
    { title: 'Random', value: 'random' },
    { title: 'None', value: 'none' },
  ]

  get isFreeAccount() {
    return ['VIDEO_FREE'].includes(this.networkType)
  }

  get isBasicPlan() {
    return ['VIDEO_BASIC'].includes(this.networkType)
  }

  get downloadBtnText() {
    return this.isFreeAccount ? '🔒 Download' : 'Download'
  }

  handleRender() {
    if (this.isFreeAccount) {
      this.showPlans = true
      return
    }
    this.$emit('render', {
      resolution: this.resolution,
      orientation: this.orientation,
      subtitle: this.subtitle,
      transition: this.transition,
      backgroundFit: this.backgroundFit,
    })
  }
}
