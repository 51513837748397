var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "" },
      on: {
        closeModal: function () {
          _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f4 light-gray fw5" }, [
          _vm._v("Export Setting"),
        ]),
      ]),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "div",
            { staticClass: "w-100 white mw-900" },
            [
              _c("div", { staticClass: "mb2" }, [_vm._v("Resolution")]),
              _c(
                "div",
                { staticClass: "flex mb2" },
                _vm._l(_vm.resolutionList, function (res) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mr2 mb2 pa2 ba br2 f6 flex items-center pointer",
                      class:
                        _vm.resolution === res.value
                          ? "b--adori-red bg-adori-subtle-red white	"
                          : "b--grey gray",
                      on: {
                        click: function ($event) {
                          _vm.resolution = res.value
                        },
                      },
                    },
                    [
                      res.isPremium && _vm.isBasicPlan
                        ? _c("span", [_vm._v("🔒 ")])
                        : _vm._e(),
                      _vm._v(" " + _vm._s(res.title) + "\n        "),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "mb2" }, [_vm._v("Subtile")]),
              _c(
                "div",
                { staticClass: "flex mb3" },
                _vm._l(_vm.subtitleList, function (item) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mr2 mb2 pa2 ba br2 f6 flex items-center pointer",
                      class:
                        _vm.subtitle === item.value
                          ? "b--adori-red bg-adori-subtle-red white	"
                          : "b--grey gray",
                      on: {
                        click: function ($event) {
                          _vm.subtitle = item.value
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "mb2" }, [_vm._v("Background fit")]),
              _c(
                "div",
                { staticClass: "flex mb2" },
                _vm._l(_vm.backgroundFitList, function (item) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mr2 mb2 pa2 ba br2 f6 flex items-center pointer",
                      class:
                        _vm.backgroundFit === item.value
                          ? "b--adori-red bg-adori-subtle-red white	"
                          : "b--grey gray",
                      on: {
                        click: function ($event) {
                          _vm.backgroundFit = item.value
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "mb2" }, [_vm._v("Transition")]),
              _c(
                "div",
                { staticClass: "flex mb2" },
                _vm._l(_vm.transitionList, function (item) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mr2 mb2 pa2 ba br2 f6 flex items-center pointer",
                      class:
                        _vm.transition === item.value
                          ? "b--adori-red bg-adori-subtle-red white	"
                          : "b--grey gray ",
                      on: {
                        click: function ($event) {
                          _vm.transition = item.value
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]
                  )
                }),
                0
              ),
              _c("base-button-red", {
                staticClass: "w-100 mt3",
                attrs: {
                  text: _vm.downloadBtnText,
                  loading: _vm.isRendering,
                  onClick: _vm.handleRender,
                },
              }),
            ],
            1
          ),
          _vm.showPlans
            ? _c("ModalPlans", {
                attrs: { customModal: "" },
                on: {
                  closeModal: function ($event) {
                    _vm.showPlans = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }